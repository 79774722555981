export const TabKeys = {
  profile: 'profile',
  contacts: 'contacts',
  saved: 'saved',
  points: 'points',
  credential: 'credential',
  matchmaking: 'matchmaking',
  preferences: 'preferences',
  entries: 'entries',
}
