<script>
import { mapGetters, mapState } from 'vuex'
import UserProfileTabWrapper from '@/components/Event/UserProfileTabWrapper.vue'
import { commonTimeFormat } from '@/utils/dateHelpers'

export default {
    name: 'UserListEntries',
    components: { UserProfileTabWrapper },
    data: () => ({
        search: '',
        totalRows: 0,
        dynamicHeaders: [],
        rows: [],
        loading: false,
        loadingStates: {},
        options: {},
        awaitingSearch: false,
    }),
    computed: {
        headers() {
            return [
                // === isRep or Default ===
                { 
                    text: this.isRep ? this.$t('bz2.event.rep.forms.labels.client') : this.$t('bz2.event.rep.forms.labels.company'),
                    align: 'start',
                    sortable: false,
                },
                { 
                    text: this.$t('bz2.event.rep.forms.labels.type_form'),
                    align: 'start',
                    sortable: false,
                    hidden: this.isRep ? true : false,
                },
                ...this.dynamicHeaders,
                { 
                    text: this.$t('bz2.event.rep.forms.labels.status'),
                    align: 'start',
                    sortable: false,
                    hidden: this.isRep ? true : false,
                },
                { 
                    text: this.$t('bz2.event.rep.forms.labels.assitence'),
                    align: 'start',
                    sortable: false,
                },
                { 
                    text: this.isRep ? this.$t('bz2.event.rep.forms.labels.received') : this.$t('bz2.event.rep.forms.labels.sent'),
                    align: 'start',
                    sortable: false,
                },
                { 
                    text: '',
                    align: 'start',
                    sortable: false,
                    hidden: this.isRep ? false : true,
                },
            ].filter((row) => !row.hidden)
        },
        ...mapGetters({
            isRep: ['session/isRep'],
            isAdmin: ['session/isAdmin'],
        }),
        ...mapState({
            registry: (state) => state.event.constants.registry.id,
        }),
        parsedRows() {
            return this.rows.map((row) => ({
                ...row,
                fullname_from: `${row.user_from.first_name} ${row.user_from.last_name}`,
                fullname_to: `${row.user_to.first_name} ${row.user_to.last_name}`,
                form_submission: row.form_submission.filter(fs => fs.is_visible_table),
                parsedCreatedAt: commonTimeFormat(row.created_at),
            }))
        },
        footer_props() {
            const obj = {
                'items-per-page-options': [
                    10, 20, 30, 40, 50,
                ],
            }
            return obj
        },
        isMobile() {
            return this.$vuetify.breakpoint.mdAndDown
        },
    },
    created() {
        this.getRows()
    },
    methods: {
        getRows() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            this.$store
                .dispatch('forms/listEntries', {
                    params: {
                        page: page,
                        rowsPerPage: itemsPerPage,
                        sortBy: sortBy,
                        sortDesc: sortDesc,
                        search: this.search,
                        registry_id: this.registry,
                    },
                })
                .then((res) => {
                    const { total, data } = res.data.data
                    this.rows = data
                    this.totalRows = total
                    if (this.isRep) {
                        if (data.length > 0 && data[0].custom_form) {
                            this.generateHeaders(data[0].custom_form.fields)
                        } else {
                            console.warn('No submission_form found in the data.')
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        generateHeaders(custom_form) {
            this.dynamicHeaders = Object.keys(custom_form)
            .filter(key => custom_form[key].is_visible_table)
            .map(key => ({
                text: custom_form[key].label || key.replace(/_/g, ' ').toUpperCase(),
                value: `custom_form.${key}`,
                align: 'start',
                sortable: false,
            }))
        },
        submitAssistence(id, bool) {
            this.$set(this.loadingStates, id, { checkLoading: bool, closeLoading: !bool })
            this.$store
                .dispatch('forms/updateAssistence', {
                    id: id,
                    assistance: bool,
                })
                .then(() => {
                    this.getRows()
                })
                .finally(() => {
                    this.$set(this.loadingStates, id, { checkLoading: false, closeLoading: false })
                })
        },
        deleteEntrie(id) {
            this.$set(this.loadingStates, id, { deleteLoading: true })
            this.$store
                .dispatch('forms/deleteEntrie', { id: id })
                .then(() => {
                    this.getRows()
                })
                .finally(() => {
                    this.$set(this.loadingStates, id, { deleteLoading: false })
                })
        },
    },
}
</script>
<template>
    <UserProfileTabWrapper>
        <v-container class="tw-divide-gray-200" fluid>
            <section class="tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
                <div class="tw-mx-auto tw-max-w-6xl tw-divide-y tw-divide-gray-200">
                    <div class="tw-space-y-1">
                        <h3 class="tw-text-lg tw-font-bold tw-leading-6 tw-text-gray-900">
                            {{ $t('bz2.event.rep.data_entries') }}
                        </h3>
                    </div>
                    <div class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-py-4">
                        <v-card class="tw-rounded-xl tw-shadow-md tw-px-4">
                            <v-data-table
                                :headers="headers" 
                                :items="parsedRows"
                                :options.sync="options" 
                                :server-items-length="totalRows" 
                                :loading="loading"
                                class="elevation-0" 
                                :footer-props="footer_props"
                                :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
                            >
                                <template #body="{ items }">
                                    <tbody v-if="items.length" key="main-table">
                                        <tr v-for="item in items" :key="item.id">
                                            <td class="tw-whitespace-nowrap">
                                                {{ isRep ? item.fullname_from : item.user_to.notes }}
                                            </td>
                                            <td v-if="!isRep" class="tw-whitespace-nowrap">
                                                {{ item.custom_form.title }}
                                            </td>
                                            <template v-if="isRep">
                                                <td v-for="fs in item.form_submission" :key="fs.uid">
                                                    {{ 
                                                        ['radio', 'checkbox', 'select'].includes(fs.field_type) 
                                                        ? fs.options.find((row) => row.value == fs.model).text 
                                                        : fs.model  
                                                    }}
                                                </td>
                                            </template>
                                            <td v-if="!isRep" class="tw-whitespace-nowrap">
                                                <v-chip x-small :color="item.status ? 'green' : 'red'" outlined class="tw-ml-1">
                                                    {{ item.status ? $t('bz2.event.rep.forms.labels.accepted') : $t('bz2.event.rep.forms.labels.pending') }}
                                                </v-chip>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <v-chip x-small :color="item.assistance ? 'green' : 'red'" outlined class="tw-ml-1">
                                                    {{ item.assistance ? $t('bz2.event.rep.forms.labels.attended') : $t('bz2.event.rep.forms.labels.no_attended') }}
                                                </v-chip>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                {{ item.parsedCreatedAt }}
                                            </td>
                                            <td v-if="isRep || isAdmin" class="tw-flex tw-items-center">
                                                <v-btn 
                                                    v-if="isRep"
                                                    fab text small
                                                    class="tw-text-green-500 focus:tw-outline-none"
                                                    :disabled="item.assistance"
                                                    :loading="loadingStates[item.id] && loadingStates[item.id].checkLoading"
                                                    @click="submitAssistence(item.id, true)"
                                                >
                                                    <v-icon>check</v-icon>
                                                </v-btn>
                                                <v-btn 
                                                    v-if="isRep"
                                                    fab text small
                                                    class="tw-text-red-500 focus:tw-outline-none"
                                                    :disabled="!item.assistance"
                                                    :loading="loadingStates[item.id] && loadingStates[item.id].closeLoading"
                                                    @click="submitAssistence(item.id, false)"
                                                >
                                                    <v-icon>close</v-icon>
                                                </v-btn>
                                                <v-btn 
                                                    v-if="isAdmin"
                                                    fab text small
                                                    class="tw-text-red-500 focus:tw-outline-none"
                                                    :loading="loadingStates[item.id] && loadingStates[item.id].deleteLoading"
                                                    @click="deleteEntrie(item.id)"
                                                >
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </section>
        </v-container>
    </UserProfileTabWrapper>
</template>