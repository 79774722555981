<template>
  <renderless-layout :full-screen="true">
    <RegistryLayout>
      <RegistryHeader :slug="slug" />
      <main>
        <!-- ====== Register Background ====== -->
        <div class="tw-aspect-w-16 tw-aspect-h-5 tw-relative tw-bg-gray-900">
          <div class="tw-absolute tw-inset-0">
            <img aria-hidden="true" class="tw-h-full tw-w-full tw-object-cover" :src="bgUrl" />
            <div
              v-if="false"
              class="tw-absolute tw-inset-0 tw-bg-gradient-to-r tw-from-gray-900 tw-to-transparent"
              style="mix-blend-mode: multiply"
            />
          </div>
          <div v-if="current_form.label" class="tw-relative lg:tw-py-24 xl:tw-absolute">
            <div class="tw-mx-auto tw-max-w-7xl tw-pl-4 tw-pr-8 sm:tw-px-6 lg:tw-px-8">
              <h1 class="tw-text-4xl tw-font-extrabold tw-tracking-tight tw-text-slate-50 sm:tw-text-5xl lg:tw-text-6xl">
                {{ current_form.label }}
              </h1>
              <p class="tw-mt-6 tw-max-w-3xl tw-text-xl tw-text-slate-300">
                {{ current_form.description }}
              </p>
            </div>
          </div>
        </div>
        <!-- ====== Register or Custom Forms ====== -->
        <section class="tw-relative tw-bg-slate-100 tw-p-0 md:tw-py-12">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            :disabled="loading"
            @submit.prevent="handleSubmit"
          >
            <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-0 sm:tw-px-4 md:tw-max-w-5xl lg:tw-px-8">
              <!-- ====== Form Register Default ====== -->
              <template v-if="!isActiveCustomForm">
                <v-card class="mx-auto overflow-hidden tw-rounded-xl tw-shadow-md">
                  <v-container class="tw-px-6 tw-py-5">
                    <h2 class="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                      {{ $t('pages.registry.register.main_form_title') }}
                    </h2>
                  </v-container>
                  <v-divider class="tw-border-gray-100"/>
                  <v-container class="tw-px-6 tw-py-4">
                    <div class="tw-my-4">
                      <div class="tw-grid lg:tw-grid-cols-3">
                        <div class="lg:tw-col-span-2">
                          <v-text-field
                            ref="email"
                            v-model="form.email"
                            prepend-inner-icon="alternate_email"
                            :label="$t('forms.email')"
                            type="email"
                            outlined
                            validate-on-blur
                            :rules="[rules.required, rules.email]"
                            rounded
                          />
                        </div>
                      </div>
                      <div
                        v-if="registry.has_password"
                        class="tw-grid tw-gap-6 lg:tw-grid-cols-2"
                      >
                        <div class="">
                          <v-text-field
                            v-model="form.password"
                            :label="$t('forms.password')"
                            :rules="[rules.required]"
                            outlined
                            :append-icon="
                              show_password ? 'visibility_off' : 'visibility'
                            "
                            :type="show_password ? 'text' : 'password'"
                            validate-on-blur
                            rounded
                            @click:append="show_password = !show_password"
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="form.password_confirmation"
                            :label="$t('forms.confirm_password')"
                            :append-icon="
                              show_password_confirmation
                                ? 'visibility_off'
                                : 'visibility'
                            "
                            :type="
                              show_password_confirmation ? 'text' : 'password'
                            "
                            :rules="[rules.required, passwordMatch]"
                            outlined
                            validate-on-blur
                            required
                            rounded
                            @click:append="
                              show_password_confirmation =
                                !show_password_confirmation
                            "
                          />
                        </div>
                      </div>
                      <div class="tw-grid tw-gap-6 lg:tw-grid-cols-2">
                        <div class="">
                          <v-text-field
                            v-model="form.first_name"
                            :label="$t('forms.first_name')"
                            :rules="[rules.required]"
                            type="text"
                            outlined
                            validate-on-blur
                            required
                            rounded
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="form.last_name"
                            :label="$t('forms.last_name')"
                            :rules="[rules.required]"
                            type="text"
                            outlined
                            validate-on-blur
                            required
                            rounded
                          />
                        </div>
                      </div>
                      <div class="tw-grid tw-gap-x-6 tw-gap-y-2 lg:tw-grid-cols-2">
                        <template v-for="default_field in default_fields">
                          <div
                            v-if="default_field.is_active"
                            :key="default_field.name"
                          >
                            <select-countries
                              v-if="default_field.name === 'country_code'"
                              v-model="form[default_field.name]"
                              :label="default_field.label"
                              :rules="
                                default_field.is_required ? [rules.required] : []
                              "
                              outlined
                              rounded
                              validate-on-blur
                            />
                            <v-text-field
                              v-else
                              v-model="form[default_field.name]"
                              :label="default_field.label"
                              :name="default_field.name"
                              :rules="
                                default_field.is_required ? [rules.required] : []
                              "
                              type="text"
                              outlined
                              validate-on-blur
                              rounded
                            />
                          </div>
                        </template>
                      </div>
                      <DynamicFields :fields="registry_form_submission" />
                    </div>
                  </v-container>
                </v-card>
              </template>
              <!-- ====== Custom Form ====== -->
              <template v-if="isActiveCustomForm">
                <v-card class="tw-relative mx-auto sm:overflow-hidden md:tw-rounded-xl tw-shadow-sm">
                  <v-container class="tw-px-6 tw-py-5">
                    <h2 v-if="custom_form_submission.title" class="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                      {{ custom_form_submission.title }}
                    </h2>
                    <p v-if="custom_form_submission.description" class="tw-text-gray-500 tw-m-0 tw-mt-1">{{ custom_form_submission.description }}</p>
                  </v-container>
                  <v-divider class="tw-border-gray-100"/>
                  <v-container class="tw-px-6 tw-py-4">
                    <div class="tw-my-4">
                      <DynamicFields v-if="custom_form_submission_fields" :fields="custom_form_submission_fields" />
                    </div>
                  </v-container>
                </v-card>
              </template>

              <!-- ====== Stripe Payment ====== -->
              <section v-if="registry.has_payment && !isActiveCustomForm">
                <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-4">
                  <!-- ====== Detail Card ====== -->
                  <v-card class="tw-rounded-3xl tw-shadow-md tw-col-span-3 tw-px-6 tw-py-6">
                    <div>
                      <h2 class="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">
                        {{ $t('bz2.landing.payment.title') }}
                      </h2>
                    </div>
                    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 tw-mt-6">
                      <v-text-field 
                        v-model="card_email" 
                        :label="$t('forms.email')" 
                        :rules="[rules.required]" 
                        type="email" 
                        outlined 
                        validate-on-blur 
                        required 
                        rounded
                        class="tw-text-sm" 
                        :class="registry.is_multi_ticket ? '': 'tw-col-span-2'"
                        hide-details="auto" 
                      />
                      <v-autocomplete
                        v-if="registry.is_multi_ticket"
                        v-model="ticket_select"
                        :items="registry.registry_tickets"
                        hide-no-data
                        hide-selected
                        item-text="ticket_label_key"
                        item-value="id"
                        :label="$t('bz2.landing.payment.ticket')" 
                        return-object
                        outlined
                        rounded
                        hide-details="auto" 
                        class="tw-text-sm" 
                      />
                    </div>
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-mt-4">
                      <v-text-field 
                        v-model="card_name" 
                        :label="$t('bz2.landing.payment.name_on_card')" 
                        type="text" 
                        outlined 
                        rounded 
                        class="tw-text-sm tw-col-span-6" 
                        hide-details="auto" 
                      />
                      <template v-if="registry.publishable_key">
                        <!-- <script type="application/javascript" defer src="https://js.stripe.com/v3/"/> -->
                        <StripeElements
                          ref="elms"
                          v-slot="{ elements }"
                          :stripe-key="registry.publishable_key"
                          :instance-options="instanceOptions"
                          :elements-options="elementsOptions"
                          class="bz-card-element tw-col-span-6"
                        >
                          <StripeElement
                            ref="card"
                            type="card"
                            :elements="elements"
                            :options="cardOptions"
                          />
                        </StripeElements>
                      </template>
                    </div>
                    <div class="bz-powered-by-stripe tw-text-xs tw-text-gray-400 tw-px-4 tw-pt-4">
                      <span>Powered by</span>
                      <img :src="require('@/assets/stripe.svg')" />
                    </div>
                  </v-card>
                   <!-- ====== Detail Type User ====== -->
                  <v-card class="tw-rounded-3xl tw-shadow-md tw-col-span-2 tw-px-6 tw-py-6">
                    <template v-if="registry.is_multi_ticket">
                      <template v-if="ticket_select">
                        <span class="tw-text-sm tw-text-gray-400 tw-mb-2">{{ ticket_select.ticket_label_key }}</span>
                        <p class="tw-font-bold tw-text-3xl">$ {{ ticket_select.price_ticket }}</p>
                        <p class="tw-text-sm tw-text-gray-400" v-html="ticket_select.ticket_description" />
                      </template>
                      <template v-else>
                        <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                          <v-icon class="tw-text-5xl">category</v-icon>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <span class="tw-text-sm tw-text-gray-400 tw-mb-2">General</span>
                      <p class="tw-font-bold tw-text-3xl">$ {{ registry.price_event }}</p>
                      <p class="tw-text-sm tw-text-gray-400" v-html="registry.price_event_description" />
                    </template>
                  </v-card>
                </div>
              </section>  
              
              <!-- ====== Terminos y condiciones ====== -->
              <v-card class="mx-auto sm:overflow-hidden md:tw-rounded-xl tw-shadow-sm">
                  <v-container class="tw-px-4 tw-py-6 sm:tw-p-6">
                    <div>
                      <h2 class="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">{{ $t('forms.terms') }}</h2>
                      <p v-if="current_form.terms_and_conditions_company" class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                        {{ current_form.terms_and_conditions_company }}
                      </p>
                      <p v-else class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                        {{ $t('pages.event.terms') }}
                      </p>
                    </div>
                    <div class="tw-mt-6 tw-space-y-2">
                      <div class="tw-grid lg:tw-grid-cols-3">
                        <div class="tw-flex tw-justify-start lg:tw-col-span-2">
                          <v-checkbox
                            id="terms"
                            v-model="form.terms"
                            :rules="[rules.required]"
                            hide-details=""
                            class="tw-mt-0"
                          />
                          <label for="terms" class="tw-text-gray-500 tw-text-sm">
                            <span>
                              {{ $t('terms.term1') }}
                              <a :href="linkDoc" target="_blank">{{
                                $t('terms.term2')
                              }}</a
                              >.
                            </span>
                            {{ $t('pages.event.terms_accept') }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </v-container>
              </v-card>

              <!-- ====== Button Action ====== -->
              <div class="tw-pb-6 tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-justify-between sm:tw-flex-row">
                <transition name="fade-transition" mode="out-in">
                  <v-row v-if="errorAlert">
                    <v-col cols="12">
                      <v-alert
                        v-model="errorAlert"
                        dismissible
                        color="red"
                        border="left"
                        elevation="2"
                        colored-border
                        icon="error"
                        transition="fade-transition"
                      >
                        {{ errorMsg }}</v-alert
                      >
                    </v-col>
                  </v-row>
                </transition>
                <v-spacer />
                <AppBtn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  icon="check"
                  :style="colorBtn"
                >
                  {{ $t('app.register') }}
                </AppBtn>
              </div>
            </div>
          </v-form>
        </section>
        <!-- ====== Support Email and WhatsApp ====== -->
        <section 
          v-if="registry.has_support"
          class="tw-bg-slate-100 tw-pt-10 tw-pb-6"
        >
          <div class="tw-mx-auto tw-max-w-sm">
            <RegistrySupportText
              class="tw-mb-0 tw-pt-4 tw-pb-4 tw-text-center tw-text-sm tw-leading-5 tw-text-gray-700"
            />
          </div>
        </section>
      </main>
    </RegistryLayout>
  </renderless-layout>
</template>

<script>
import RegistryLayout from './RegistryLayout.vue'
import SelectCountries from '@/components/FormInputs/SelectCountries.vue'
import RegistrySupportText from './RegistrySupportText.vue'
import RegistryHeader from './RegistryHeader.vue'
import { mapGetters, mapState } from 'vuex'
import { rules } from '@/utils/rules'
import { matchCols } from '@/utils/formCols'
import { RegistryStateKeys } from '@/enums/RegistryStateKeys'
import { isPastDate } from '@/utils/dateHelpers'
import AppBtn from '@/components/App/AppBtn.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'
// --------------------- Payment --------------------- //
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import DynamicFields from '@/components/DynamicFields.vue'

export default {
  name: 'BizzDomeUiRegister',
  components: {
    RegistryLayout,
    SelectCountries,
    AppBtn,
    RegistryHeader,
    RegistrySupportText,
    // Payment
    StripeElements,
    StripeElement,
    DynamicFields,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    matchCols,
    loading: false,
    show_password: false,
    show_password_confirmation: false,
    errorAlert: '',
    errorMsg: '',
    rules,
    valid: true,
    form: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      company: '',
      title: '',
      country_code: '',
      address: '',
      city: '',
      phone_number: '',
    },
    registry_form_submission: [],
    default_fields: [],
    onlyNumber,
    // ---------------- New Data Payment ---------------- //
    instanceOptions: {},
    elementsOptions: {},
    cardOptions: {
      value: {
        postalCode: '',
      },
    },
    card_email: '',
    card_name: '',
    ticket_select: 0,
    stripeLoaded: false,
    // Custom Form
    custom_form_submission: [],
    custom_form_submission_fields: [],
  }),
  head() {
    return {
      title: {
        inner: this.registry.label,
      },
      style: [
        {
          type: 'text/css',
          inner: this.current_landing.custom_css,
        },
      ],
    }
  },
  computed: {
    colorBtn() {
      const bg = this.current_landing.ui_color ? this.current_landing.ui_color : '#3565af'
      const hex = this.current_landing.ui_contrast ? this.current_landing.ui_contrast : '#3565af'
      return {
        'background-color': `${bg} !important`,
        'color': `${hex}`,
      }
    },
    bgUrl() {
      return this.current_form.header_url
        ? this.current_form.header_url
        : require('@/assets/bg_login_bizzdome.jpg')
    },
    linkDoc() {
      return this.current_form.terms_and_conditions_link
        ? this.current_form.terms_and_conditions_link
        : this.$i18n.t('terms.term_link')
    },
    wa_msg() {
      return this.$i18n.locale === 'en' ? 'Hi%20!' : 'Hola%20!'
    },
    wa_link() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return `https://api.whatsapp.com/send?phone=51994225574&text=${this.wa_msg}`
      }
      return `https://web.whatsapp.com/send?phone=51994225574&text=${this.wa_msg}`
    },
    toRegister() {
      return {
        name: 'registry.register',
        params: { slug: this.slug },
      }
    },
    toLogin() {
      return {
        name: 'registry.login',
        params: { slug: this.slug },
      }
    },
    canRegister() {
      return !this.registry.is_registration_disabled
    },
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
      registry_status: (state) => state.registry.show.registry_status,
      roomLink: (state) => state.registry.toPath.link,
    }),
    current_landing() {
      const landing = this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
      if (!landing) {
        return {}
      }
      return landing
    },
    hasPayment() {
      return this.registry.has_payment
    },
    formId() {
      return this.$route.params.form_id
    },
    isActiveCustomForm() {
      return !!this.formId
    },
    formData() {
      return this.getForm(this.formId)
    },
    ...mapGetters('registry',['getForm']),
  },
  watch: {
    current_language_key: {
      handler() {
        this.$nextTick(() => {
          this.initForm()
        })
      },
    },
    formId(newFormId) {
      if (newFormId) {
        this.initCustomForm()
      }
    },
  },
  mounted() {
    if (this.isActiveCustomForm) {
      this.initCustomForm()
    }
    this.initForm()
    if (!this.canRegister) {
      this.$router.push({
        name: 'registry.home',
        params: { slug: this.slug },
      })
    }
    if (!this.registry.end_date_time) {
      return
    }
    if (isPastDate(this.registry.end_date_time)) {
      this.$router.push({
        name: 'registry.thank-you',
        params: { slug: this.slug },
      })
    }
  },
  methods: {
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.isActiveCustomForm) {
        this.submitCustomForm()
      } else {
        this.register()
      }
    },
    initCustomForm() {
      const current_custom_form = JSON.parse(JSON.stringify(this.formData))
      this.custom_form_submission = current_custom_form
      this.custom_form_submission_fields = current_custom_form.fields
    },
    initForm() {
      const current_form = JSON.parse(JSON.stringify(this.current_form))
      console.log(current_form)
      this.registry_form_submission = current_form.fields

      this.default_fields = current_form.default_fields.filter(
        (row) => row.name !== 'email',
      )
    },
    passwordMatch() {
      return this.form.password === this.form.password_confirmation
        ? true
        : 'Contraseña no es igual'
    },
    register() {
      /**
       * key -> value
       * custom -> [ ...fields ]
       */
      // --------------- Form Validate --------------- //
      if (!this.$refs.form.validate()) {
        return
      }
      // --------------- Payload Register --------------- //
      this.errorAlert = false
      const payload = {
        uuid: this.current_form.uuid,
        body: {
          ...this.form,
          registry_form_submission: addModelText(this.registry_form_submission),
        },
      }
      // --------------- Actions --------------- //
      this.loading = true
      if (this.hasPayment) {
        const groupComponent = this.$refs.elms
        const cardComponent = this.$refs.card
        const cardElement = cardComponent.stripeElement
        groupComponent.instance.createToken(cardElement).then(result => {
          // --------------- Payload Stripe --------------- //
          const payload_stripe = {
            card_email: this.card_email,
            card_name: this.card_name,
            description_event: this.registry.is_multi_ticket ? this.ticket_select.ticket_label_key : 'General',
            amount_event: this.registry.is_multi_ticket ? this.ticket_select.price_ticket : this.registry.price_event,
            registry_id: this.registry.id,
            ...result,
          }
          this.$store.dispatch('session/paymentEvent', payload_stripe)
            .then((res) => {
              console.log(res)
              if (res.done) {
                this.registerSingle(payload)
                return
              } else {
                this.loading = false
                this.errorAlert = true
                this.errorMsg = res.fail
              }
            })
        })
      } else {
        this.registerSingle(payload)
      }
    },
    registerSingle(payload) {
      return this.$store
        .dispatch('session/registerEvent', payload)
        .then(() => {
          if (this.registry_status == RegistryStateKeys.open) {
            const link = this.roomLink ? this.roomLink : '/event/'+this.registry.slug
            this.$router.push(link)
            return
          }
          this.$router.push({
            name: 'registry.confirmed',
            params: { slug: this.slug },
          })
          return
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitCustomForm() {
      const payload = {
          uuid: this.current_form.uuid,
          body: {
            custom_form_id: this.formId,
            registry_id: this.registry.id,
            form_submission: addModelText(this.custom_form_submission_fields),
            language: this.current_language_key,
          },
      }
      this.$store.dispatch('registry/submissionCustomForm', payload)
        .then(() => {
          this.$router.push({
            name: 'registry.confirmed',
            params: { slug: this.slug },
          })
        })
        .finally(() => {
          this.clearCustomFormFields()
        })
    },
    clearCustomFormFields() {
      this.custom_form_submission_fields.forEach(field => {
        switch (field.field_type) {
          case 'input':
          case 'textarea':
          case 'select':
            field.model = ''
            break
          case 'checkbox':
            field.model_multiple = []
            break
          case 'radio':
            field.model = null
            break
          case 'upload':
            field.model = null
            break
          default:
            field.model = ''
        }
      })
    },
    goToHome() {
      this.$router.push({
        name: 'registry.home',
        params: { slug: this.slug },
      })
    },
    goToRegister() {
      this.$router.push({
        name: 'registry.register',
        params: { slug: this.slug },
      })
    },
    goToLogin() {
      this.$router.push({
        name: 'registry.login',
        params: { slug: this.slug },
      })
    },
  },
}
</script>

<style lang="scss">
.bz-card-element {
  border: 1px solid #aaaaaa;
  padding: 1.2rem;
  border-radius: 5rem;
}
.bz-powered-by-stripe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: .5rem;
  padding-bottom: .5rem;
  img {
    height: 1rem;
    margin-left: .3rem;
  }
}
</style>