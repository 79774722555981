<template>
  <!-- app -->
  <!-- v-if="isAuth" -->
  <v-navigation-drawer
    v-if="isAuth"
    v-model="drawer"
    app
    floating
    dark
    left
    color="primary darken-4 tw-z-20"
    :src="require('@/assets/bg_login_bizzdome.jpg')"
  >
    <!-- style="mix-blend-mode: multiply" -->
    <div
      class="tw-pointer-events-none tw-absolute tw-inset-0 tw-bg-gradient-to-b tw-from-primary-900 tw-to-transparent"
    />
    <v-toolbar class="transparent">
      <!-- src="https://www.doctosalud.com/wp-content/uploads/2020/06/logo-1.png"  http://www.intelcorp.xyz/chinalco/wp-content/uploads/2020/12/logo.png


        :src="require('../assets/logos/logo-gulda.png')"-->
      <!-- src="https://www.intelcorp.xyz/chinalco/wp-content/uploads/2020/12/logo.png" -->
      <v-img
        :src="require('@/assets/logo-bizzdome-blanco.svg')"
        height="60"
        contain
        class="mb-1 d-flex"
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0 transparent"
            align="center"
            justify="center"
          >
            <v-progress-circular size="32" indeterminate color="white" />
          </v-row>
        </template>
      </v-img>
    </v-toolbar>

    <v-divider />
    <!-- <v-list class="py-4">
      <v-list-item two-line @click="addNotification">
        <v-list-item-avatar
          class="tw-border-solid tw-border-3 tw-border-primary-200"
        >
          <v-img :src="user.avatar" position="top" class="d-flex">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 transparent"
                align="center"
                justify="center"
              >
                <v-progress-circular size="32" indeterminate color="white" />
              </v-row>
            </template>
          </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ fullName }} </v-list-item-title>
          <v-list-item-subtitle>{{ user.formatedRoles }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <!-- <v-divider color="white" /> -->
    <v-list class="tw-py-2">
      <v-list-item @click="goToEvent">
        <v-list-item-icon>
          <v-icon>preview</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('admin.app.pre_render') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider color="white" />
    <!-- v-if="onBoardingComplete" -->
    <v-list v-if="isAdmin" nav class="py-4">
      <!-- v-for="item in items.user" -->
      <v-list-item
        v-for="item in links"
        :key="item.title"
        dense
        link
        :to="item.route"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div
      v-if="$vuetify.breakpoint.mdAndDown"
      class="pa-2 tw-space-y-4 md:tw-hidden"
    >
      <div class="tw-w-full">
        <LocaleSwitcher block />
      </div>
      <v-btn
        block
        rounded
        class="primary--text text--darken-4 tw-font-bold tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
        color="white "
        @click.stop="logout"
      >
        <v-icon left>power_settings_new</v-icon>{{ $t('app.logout') }}
      </v-btn>
    </div>
    <template #append>
      <div class="pa-2 tw-space-y-4">
        <v-btn
          block
          text
          depressed
          color="grey lighten-4"
          x-small
          disabled
          @click.stop="() => null"
        >
          <v-icon left>verified_user</v-icon>
          Version: {{ version_build }}
        </v-btn>
        <div class="tw-w-full">
          <LocaleSwitcher block />
        </div>
        <!-- <v-btn
          v-if="!$vuetify.breakpoint.mdAndDown"
          block
          rounded
          class="
            tw-font-bold tw-outline-none
            primary--text
            text--darken-4
            focus:tw-ring focus:tw-ring-primary-300
          "
          color="white "
          @click.stop="logout"
        >
          <v-icon left>power_settings_new</v-icon>{{ $t('app.logout') }}
        </v-btn> -->
        <AppBtn
          v-if="!$vuetify.breakpoint.mdAndDown"
          color="white"
          block
          @click="logout"
        >
          <v-icon left>power_settings_new</v-icon>
          {{ $t('app.logout') }}
        </AppBtn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatterArray } from '@/utils/formatterArray'
import AppBtn from '@/components/App/AppBtn.vue'
import LocaleSwitcher from '../LocaleSwitcher.vue'
const _links = [
  {
    title: 'Dashboard',
    label: 'dashboard',
    icon: 'dashboard',
    route: '/admin/dashboard',
  },
  {
    title: 'Registros y Landings',
    label: 'events',
    icon: 'description',
    route: '/admin/registries',
  },
  {
    title: 'Cuentas de Usuario',
    label: 'users',
    icon: 'people',
    route: '/admin/users',
  },
  {
    title: 'Salas Virtuales',
    label: 'rooms',
    icon: 'local_activity',
    route: '/admin/booths',
  },
  {
    title: 'Libreria de Contenido',
    label: 'content',
    icon: 'smart_button',
    route: '/admin/content-library',
  },
  {
    title: 'Emails',
    label: 'emails',
    icon: 'alternate_email',
    route: '/admin/emails',
  },

  {
    title: 'Rastreo y Metricas',
    label: 'metrics',
    icon: 'insights',
    route: '/admin/metrics',
  },
  {
    title: 'Q&A',
    label: 'qa',
    icon: 'question_answer',
    route: '/admin/threads',
  },
  {
    title: 'Mensajes',
    label: 'messages',
    icon: 'chat',
    route: '/admin/channels',
  },
  // {
  //   title: 'Encuestas',
  // label: 'events',
  // icon: 'lightbulb',
  //   route: '/admin/surveys',
  // },
  {
    title: 'Formularios',
    label: 'forms',
    icon: 'description',
    route: '/admin/forms',
  },
  {
    title: 'Configuracion',
    label: 'config',
    icon: 'settings',
    route: '/admin/configs',
  },
  {
    title: 'Changelog',
    label: 'changelog',
    icon: 'published_with_changes',
    route: '/admin/changelog',
  },
]
export default {
  components: {
    AppBtn,
    LocaleSwitcher,
  },
  // data: () => ({
  //   items: {
  //     user:
  //   },
  // }),

  computed: {
    links() {
      return _links.map((link) => ({
        ...link,
        title: this.$t(`admin.home.labels.${link.label}`),
      }))
    },
    ...mapGetters({
      fullName: ['session/fullName'],
    }),
    isAdmin() {
      return this.userStore.roles.map((row) => row.name).includes('admin')
    },
    ...mapState({
      userStore: (state) => state.session.user,
      isAuth: (state) => state.session.active,
      version: (state) => state.constants.data.version,
    }),
    version_build() {
      try {
        return this.version
      } catch (error) {
        return process.env.VUE_APP_VERSION
      }
    },
    user() {
      return {
        ...this.userStore,
        formatedRoles: formatterArray().format(
          this.userStore.roles.map((row) => row.label),
        ),
      }
    },
    drawer: {
      get() {
        return this.$store.state.leftDrawer
      },
      set(val) {
        return this.$store.commit('setLeftDrawer', { bool: val })
      },
    },
    // ...mapGetters({
    //   onBoardingComplete: "onBoardingComplete",
    // userName: "userName",
    // }),
  },
  mounted() {
    console.log(process.env)
  },
  methods: {
    goToEvent() {
      // this.$router.push({
      //   name: 'event-preview.home.rewrite',
      // })

      this.$store.commit('session/setAdmin', {
        pre_event_modal: true,
        can_remember: false,
      })
    },
    addNotification() {
      // this.$store.dispatch('notifications/addNotification', {
      //   title: 'Prueba',
      //   body: 'Lorem ipsum',
      // })
    },
    logout() {
      this.$store.commit('session/setLogoutModal', {
        logoutModal: true,
      })
    },
    toHome() {
      if (this.$router.currentRoute.path === '/admin') {
        return
      }
      return this.$router.push('/admin')
    },
  },
}
</script>

<style></style>
