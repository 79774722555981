import $axios from '@/axios-silent.js'

export default class FormsServices {
    baseUrl = '/api/v1/admin/forms'
    baseUrlSec = '/api/v1/event'

    // Index
    index(params) {
        return $axios.get(this.baseUrl, params)
    }
    // Create
    store(params) {
        return $axios.post(this.baseUrl, params)
    }
    // Show
    show(id, params) {
        return $axios.get(this.baseUrl + `/${id}`, params)
    }
    // Update
    update(id, params) {
        return $axios.post(this.baseUrl + `/${id}`, {
            ...params,
            _method: 'PUT',
        })
    }
    // Delete
    destroy(id, params) {
        return $axios.delete(this.baseUrl + `/${id}`, params)
    }
    // Entrie Form
    entries(params) {
        return $axios.post(this.baseUrl + '/entrie', params)
    }
    listEntries(params) {
        return $axios.get(this.baseUrlSec + '/user-entries', params)
    }
    updateAssistence(id, params) {
        return $axios.post(this.baseUrlSec + `/user-entries/${id}`, params)
    }
    destroyEntrie(id, params) {
        return $axios.delete(this.baseUrlSec + `/user-entries-delete/${id}`, params)
    }
    export(custom_form_id, body, params) {
        return $axios.post(
            `${this.baseUrl}/export/${custom_form_id}`,
            body,
            { ...params, responseType: 'blob' },
        )
    }
}

export const FormsServicesInstance = new FormsServices()