import i18n from '@/i18n'
import { FieldTypes } from '@/models/RegistryForm'
import { VirtualRoomsServiceInstance } from '@/services/VirtualRoomsService'
import { SearchAutocompleteService } from '@/services/SearchAutocompleteService'
import { element_icons } from '@/utils/ElementTypes'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { ScheduledEmailTypeKeys } from '@/enums/ScheduledEmailTypeKeys'
import GlobalEventConfigResource from '@/services/GlobalEventConfigResource'
const notAcceptedRoles = ['user', 'master']
export const Constants = {
  namespaced: true,
  state: {
    data: {
      global_event_config: {},
      timezones: {},
      license: {},
      room_types: [],
      element_types: [],
      languages: [
        { id: 1, name: 'es', label: 'Español' },
        { id: 2, name: 'en', label: 'Ingles' },
      ],
      doorway_types: [],
      event_configs: [],
      click_types: [],
      roles: [],
      iframe_types: [],
      access_list_types: [],
      control_types: [],
      content_types: [],
      event_broadcast_style_types: [],
      event_broadcast_overlay_styles_types: [],
      scheduled_email_types: [],
      leaderboard_types: [],
      scheduled_email_recipient_types: [],
      channel_types: [],
      registries: [],
      nav_items_sizes: [
        { value: 'sm', text: 'Small' },
        { value: 'md', text: 'Medium' },
      ],
      ...FieldTypes(),
      version: '1.0.0',
      domain: '',
    },
    tenant: {
      has_duration: false,
      start_date: '',
      end_date: '',
    },
    is_loading: {
      photobooth_configs: false,
      virtual_rooms: false,
      channels: false,
      threads: false,
      content_elements: false,
      users: false,
      forms: false,
    },
    run_time: {
      photobooth_configs: [],
      virtual_rooms: [],
      channels: [],
      threads: [],
      content_elements: [],
      users: [],
      users_all: [],
      forms: [],
    },
    booth_types: [
      { value: 'Todos', text: 'Todos' },
      { value: 'Auditorium', text: 'Auditorium' },
      { value: 'Booth', text: 'Booth' },
      { value: 'Meeting', text: 'Meeting Room' },
      { value: 'Lounge', text: 'Lounge' },
      { value: 'Hall', text: 'Hall' },
      { value: 'Lobby', text: 'Lobby' },
      { value: 'Custom', text: 'Custom' },
    ],
  },
  mutations: {
    setTenant(state, obj) {
      Object.keys(obj).forEach((k) => (state.tenant[k] = obj[k]))
    },
    setRunTimeConstant(state, obj) {
      Object.keys(obj).forEach((k) => (state.run_time[k] = obj[k]))
    },
    setConstantLoading(state, obj) {
      Object.keys(obj).forEach((k) => (state.is_loading[k] = obj[k]))
    },
    init(state, payload) {
      const {
        timezones,
        room_types,
        element_types,
        languages,
        doorway_types,
        event_configs,
        click_types,
        roles,
        event_broadcast_style_types,
        event_broadcast_overlay_styles_types,
        scheduled_email_types,
        scheduled_email_recipient_types,
        channel_types,
        video_types,
        video_style_types,
        iframe_types,
        access_list_types,
        control_types,
        content_types,
        thread_types,
        question_answer_types,
        target_link_types,
        audio_types,
        webinar_types,
        registries,
        license,
        leaderboard_types,
        global_event_config,
        version,
        domain,
      } = payload
      state.data = {
        ...state.data,
        doorway_types,
        timezones,
        room_types,
        license,
        element_types,
        languages,
        event_configs,
        video_types,
        video_style_types,
        click_types,
        roles,
        iframe_types,
        event_broadcast_style_types,
        event_broadcast_overlay_styles_types,
        scheduled_email_recipient_types,
        scheduled_email_types,
        access_list_types,
        control_types,
        content_types,
        channel_types,
        thread_types,
        question_answer_types,
        target_link_types,
        audio_types,
        webinar_types,
        registries,
        leaderboard_types,
        global_event_config,
        version,
        domain,
      }
    },
    setData(state, obj) {
      Object.keys(obj).forEach((k) => (state.data[k] = obj[k]))
    },
  },
  getters: {
    hasGlobalEventConfig({ data: { global_event_config } }) {
      const {
        logo_image_url,
        contact_first_name,
        contact_last_name,
        contact_email,
        contact_phone,
      } = global_event_config

      return (
        [
          logo_image_url,
          contact_first_name,
          contact_last_name,
          contact_email,
          contact_phone,
        ].filter((row) => !!row).length == 5
      )
    },
    element_types({ data }) {
      return data.element_types.map((row) => ({
        ...row,
        icon: element_icons[row.name],
        kmjk_type: row.name,
        text: i18n.t(`admin.enums.${row.name}`),
      }))
    },
    registries({ data }) {
      return data.registries.map((row) => ({
        text: row.label,
        value: row.id,
      }))
    },
    scheduled_email_types({ data }) {
      return data.scheduled_email_types
        .filter((row) => row.name !== ScheduledEmailTypeKeys.recurrent)
        .map((row) => ({
          text: row.label,
          value: row.name,
        }))
    },
    scheduled_email_recipient_types({ data }) {
      return data.scheduled_email_recipient_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    event_broadcast_style_types({ data }) {
      return data.event_broadcast_style_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    event_broadcast_overlay_styles_types({ data }) {
      return data.event_broadcast_overlay_styles_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    doorway_types({ data }) {
      return data.doorway_types.map((row) => ({
        // text: row.label,
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    room_types({ data }) {
      return data.room_types.map((row) => ({
        // text: row.label,
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    click_types({ data: { click_types } }) {
      return click_types.map((row) => ({
        id: row.id,
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    video_types({ data: { video_types } }) {
      return video_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    video_style_types({ data: { video_style_types } }) {
      return video_style_types.map((row) => ({
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    languages({ data }) {
      return data.languages.map((row) => ({
        text: i18n.t(`app.${row.name}`),
        value: row.name,
      }))
    },
    iframe_types({ data }) {
      return data.iframe_types.map((row) => ({
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    access_list_types({ data }) {
      return data.access_list_types.map((row) => ({
        // text: row.label,
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    leaderboard_types({ data }) {
      return data.leaderboard_types.map((row) => ({
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    control_types({ data }) {
      return data.control_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    content_types({ data }) {
      return data.content_types.map((row) => ({
        // text: row.label,
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    target_link_types({ data }) {
      return data.target_link_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    webinar_types({ data }) {
      return data.webinar_types.map((row) => ({
        text: row.label,
        value: row.name,
      }))
    },
    thread_types({ data }) {
      return data.thread_types.map((row) => ({
        text: i18n.t(`admin.enums.${row.name}`),
        value: row.name,
      }))
    },
    rolesStore({ data }) {
      return data.roles
        .filter((row) => {
          return !notAcceptedRoles.includes(row.name)
        })
        .map((row) => ({ ...row, label: i18n.t(`app.roles.${row.name}`) }))
    },
    rolesSelect(_, getters) {
      return getters.rolesStore.map((row) => ({
        text: i18n.t(`app.roles.${row.name}`),
        // row.label,
        value: row.name,
      }))
    },
    tz(state) {
      const all_timezones = state.data.timezones
      // key,
      return Object.entries(all_timezones).map(([, value]) => {
        return {
          text: value.label,
          value: value.value,
        }
      })
    },
    roomsLookUp({ run_time: { virtual_rooms } }) {
      return virtual_rooms.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur,
        }
      }, {})
    },
    getRegistriesById: (state) => (id) => {
      return state.data.registries.find((row) => row.id === id)
    },
  },
  actions: {
    updateGlobalEventConfig({ commit }, { payload }) {
      return GlobalEventConfigResource.store(payload)
        .then(({ data: { global_event_config } }) => {
          commit('setData', { global_event_config })
          return global_event_config
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
    },
    loadRooms({ commit }) {
      commit('setConstantLoading', { virtual_rooms: true })
      return VirtualRoomsServiceInstance.searchAsYouType()
        .then(({ data: { data } }) => {
          commit('setRunTimeConstant', { virtual_rooms: data })
          return data
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { virtual_rooms: false })
        })
    },
    loadPhotoboothConfigs({ commit }) {
      commit('setConstantLoading', { photobooth_configs: true })
      return SearchAutocompleteService.photoboothConfigs()
        .then(({ data: { photobooth_configs } }) => {
          commit('setRunTimeConstant', { photobooth_configs })
          return photobooth_configs
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { photobooth_configs: false })
        })
    },
    loadThreads({ commit }) {
      commit('setConstantLoading', { threads: true })
      return SearchAutocompleteService.threads()
        .then(({ data: { threads } }) => {
          commit('setRunTimeConstant', { threads: threads })
          return threads
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { threads: false })
        })
    },
    loadChannels({ commit }) {
      commit('setConstantLoading', { channels: true })
      return SearchAutocompleteService.channels()
        .then(({ data: { channels } }) => {
          commit('setRunTimeConstant', { channels: channels })
          return channels
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { channels: false })
        })
    },
    loadContentLibrariesTags({ commit }, { uuid }) {
      commit('setConstantLoading', { tags: true })
      return SearchAutocompleteService.contentLibrariesTags(uuid)
        .then(({ data: { tags } }) => {
          commit('setRunTimeConstant', { tags: tags })
          return tags
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { tags: false })
        })
    },
    loadUsers({ commit }) {
      commit('setConstantLoading', { users: true })
      return SearchAutocompleteService.users()
        .then(({ data: { users } }) => {
          commit('setRunTimeConstant', { users: users })
          return users
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { users: false })
        })
    },
    // Donovann
    loadForms({ commit }, params) {
      commit('setConstantLoading', {forms: true})
      return SearchAutocompleteService.formsAll({
        params,
      })
        .then(({ data: { forms } }) => {
          commit('setRunTimeConstant', { forms: forms })
        })
        .finally(() => {
          commit('setConstantLoading', {forms: false})
        })
    },
    searchUsers({commit}, params) {
      commit('setConstantLoading', {users: true})
      return SearchAutocompleteService.usersAll({
        params,
      })
        .then(({data: { users } }) => {
          commit('setRunTimeConstant', { users_all: users })
        })
        .finally(() => {
          commit('setConstantLoading', { users: false })
        })
    },
    // End Donovann
    loadContentElements({ commit }) {
      commit('setConstantLoading', { content_elements: true })
      return SearchAutocompleteService.contentElements()
        .then(({ data: { content_elements } }) => {
          commit('setRunTimeConstant', { content_elements: content_elements })
          return content_elements
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
        .finally(() => {
          commit('setConstantLoading', { content_elements: false })
        })
    },
  },
}
