import { BannerTypeKeys, SectionTypeKeys } from '@/enums/SectionTypeKeys'
import { short_uid } from '@/helpers'

export const RegistryLandingBase = ({
  logo_url_lg = '',
  logo_url_sm = '',
  has_custom_landing = false,
  custom_css = '',
  custom_landing_html = '',
  hero_title = '',
  hero_image_url = '',
  hero_description = '',
  ui_color = '',
  ui_contrast = '',
  sections: sections_db,
  banner_sections: banner_sections_db,
  is_simple_banner = true,
  has_edit_label_btn = false,
  btn_label_login = '',
  btn_label_register = '',
}) => ({
  hero_image_url,
  logo_url_lg,
  logo_url_sm,
  hero_title,
  has_custom_landing,
  custom_css,
  custom_landing_html,
  hero_description,
  ui_color,
  ui_contrast,
  is_simple_banner,
  banner_sections: banner_sections_db ? banner_sections_db : banner_sections,
  sections: sections_db ? validateKeys(sections_db) : sections,

  preview_hero_url: require('@/assets/landing-previews/s1.png'),
  preview_footer_url: require('@/assets/landing-previews/s6.png'),

  has_edit_label_btn,
  btn_label_login,
  btn_label_register,
})
export const ActivitiesCalendarCTA = () => ({
  has_calendar: false,
  has_activities: false,
  activities_url: '',
  activities_pdf: '',
  calendar_url: '',
})
export const SocialMedias = () => ({
  logos: [],
  title: '',
})
export const SocialMedia = () => ({
  logo_url: '',
  link: '',
  uid: short_uid(),
})
export const CountdownTimer = () => ({
  // type_of_media = youtube, vimeo, image
  time_event: '',
  image_url: '',
  color_hex: '#19005A',
  title: '',
})
export const MultiButtons = () => ({
  buttons: [],
  title: '',
})
export const Mbutton = () => ({
  mbutton_image_url: '',
  has_custom_link: false,
  mbutton_url: '', //en uso
  mbutton_pdf: '',
  uid: short_uid(),
  has_cta: false, // switch/checkbox *
  cta_link_type: '', //select => internal || external || Archivo
  cta_link: '', // internal => select | key || external => textinput | url || Archivo \ link
  form_id: '',
})
export const types_of_media = [
  { value: 'youtube', text: 'Youtube' },
  { value: 'vimeo', text: 'Vimeo' },
  { value: 'image', text: 'Imagen' },
]
export const BodyHeroCTA = () => ({
  // type_of_media = youtube, vimeo, image
  type_of_media: 'image',
  image_url: '',
  video_url: '',
  title: '',
  body: '',
})
export const SponsorLogos = () => ({
  // type_of_media = youtube, vimeo, image
  logos: [],
  grid: 3,
  title: '',
})
export const ImageRatio = () => ({
  ratio_desktop_x: 16,
  ratio_desktop_y: 9,
  ratio_mobile_x: 4,
  ratio_mobile_y: 5,
  image_desktop: '',
  image_mobile: '',
})
export const Slider = () => ({
  slides: [],
  duration: 1000,
  label: '',
})
export const SliderItem = () => ({
  uid: short_uid(),
  image_url: '',
  mobile_image_url: '',
  body: '',
  has_cta: false, // switch/checkbox *
  is_btn_cta: false, //  switch/checkbox  => click a imagen o sea un btn *
  cta_link_type: '', //select => internal || external
  cta_link: '', // internal => select | key || external => textinput | url
  cta_label: '', // if btn => text input
})

// Route - Modal => internal type options
export const internalPages = [
  { text: `Register`, value: 'register' },
  { text: `Login`, value: 'login' },
]

export const grid_items = [3, 4, 5, 6].map((item) => ({
  text: `${item} Columnas`,
  value: item,
}))
export const Logo = () => ({
  logo_url: '',
  uid: short_uid(),
})
export const sections = [
  {
    name: SectionTypeKeys.main_slider,
    label: 'Main Slider',
    data: Slider(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
  {
    name: SectionTypeKeys.countdown_timer,
    label: 'Countdown Timer',
    data: CountdownTimer(),
    // preview_section_url: require('@/assets/landing-previews/s4-2.png'),
  },
  {
    name: SectionTypeKeys.social_media,
    label: 'Social Media',
    data: SocialMedias(),
    // preview_section_url: require('@/assets/landing-previews/s4-2.png'),
  },
  {
    name: SectionTypeKeys.multi_buttons,
    label: 'Multi Buttons',
    data: MultiButtons(),
    // preview_section_url: require('@/assets/landing-previews/s4-2.png'),
  },
  {
    name: SectionTypeKeys.activities,
    label: 'Actividades y calendario',
    data: ActivitiesCalendarCTA(),
    // preview_section_url: require('@/assets/landing-previews/s4-2.png'),
  },
  {
    name: SectionTypeKeys.cta,
    label: 'Call to Action',
    data: BodyHeroCTA(),
    // preview_section_url: require('@/assets/landing-previews/s2.png'),
  },
  {
    name: SectionTypeKeys.sponsor,
    label: 'Logos',
    data: SponsorLogos(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
  {
    name: SectionTypeKeys.image,
    label: 'Imagen',
    data: ImageRatio(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
  {
    name: SectionTypeKeys.image_two,
    label: 'Imagen 2',
    data: ImageRatio(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
  {
    name: SectionTypeKeys.slider,
    label: 'Slider',
    data: Slider(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
  {
    name: SectionTypeKeys.slider_two,
    label: 'Slider 2',
    data: Slider(),
    // preview_section_url: require('@/assets/landing-previews/s5.png'),
  },
].map((row) => ({ ...row, is_active: true }))

export const banner_sections = [
  {
    is_active: true,
    name: BannerTypeKeys.banner_with_text,
    label: 'Banner con texto',
    data: {
      hero_title: '',
      hero_image_url: '',
      hero_description: '',
    },
  },
  {
    name: BannerTypeKeys.simple_image,
    label: 'Imagen',
    data: {
      image_desktop: '',
      image_mobile: '',
    },
    is_active: false,
  },
]

import diff from 'microdiff'

const validateKeys = (sectionsFromDb) => {
  const section_ui_names = sections.map((row) => row.name)
  const section_db_names = sectionsFromDb.map((row) => row.name)
  const validatedSections = [...sectionsFromDb]
  section_ui_names.forEach((name, index) => {
    if (section_db_names.includes(name)) {
      // deep validate data keys
      return
    }
    validatedSections.push({ ...sections[index], is_active: false })

    // match en section interna
    // diff de lalves
    // creamos las llaves que falten
  })
  const validUpdatedSections = validatedSections.map((validatedSectionObj) => {
    if (SectionTypeKeys.slider === validatedSectionObj.name) {
      return validateSectionSlider(validatedSectionObj)
    }
    return validatedSectionObj
  })
  return validUpdatedSections
}

const validateSectionSlider = (section) => {
  return {
    ...section,
    data: {
      ...section.data,
      slides: section.data.slides.map((currentSlide) => {
        return validateSectionSliderSlide(currentSlide)
      }),
    },
  }
}
export const validateSectionSliderSlide = (currentSlide) => {
  const newSlideData = SliderItem()
  const createdKeys = diff(currentSlide, newSlideData).filter((diffedKeys) => {
    return diffedKeys.type == 'CREATE'
  })
  if (createdKeys.length > 0) {
    const newSliderFilteredKeys = createdKeys.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.path[0]]: curr.value,
      }
    }, {})
    return {
      ...currentSlide,
      ...newSliderFilteredKeys,
    }
  }
  return currentSlide
}
