import { FieldTypes } from '@/models/CustomForm'
import { FormsServicesInstance } from '@/services/FormsService'
import formErrorsHandler from '@/utils/formErrorsHandler'

export const CustomForms = {
    namespaced: true,
    state: {
        data: {
            ...FieldTypes(),
        },
        show: {
            form: {},
            loading: false,
        },
    },
    mutations: {
      setForm(state, obj) {
        Object.keys(obj).forEach((k) => (state.show.form[k] = obj[k]))
      },
      setShowLoading(state, bool) {
        state.show.loading = bool
      },
    },
    getters: {

    },
    actions: {
        index(__, payload) {
            return FormsServicesInstance.index(payload)
                .then((res) => res)
                .catch((err) => {
                    throw err
                })
        },
        async listEntries(__, payload) {
            return await FormsServicesInstance.listEntries(payload)
                .then((res) => res)
                .catch((err) => {
                    throw err
                })
        },
        store({ commit }, { payload }) {
            commit('setAppIsLoading', true, { root: true })
            return FormsServicesInstance.store(payload)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        update({ state, commit }, { payload }) {
            commit('setAppIsLoading', true, { root: true })
            return FormsServicesInstance.update( state.show.form.id, payload )
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        show({ commit }, { id }) {
            commit('setShowLoading', true)
            return FormsServicesInstance.show(id)
                .then(({ data }) => {
                    const { form } = data
                    commit('setForm', form)
                }) 
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setShowLoading', false)
                })
        },
        destroy({ commit }, { id = '' }) {
            commit('setAppIsLoading', true, { root: true })
            return FormsServicesInstance.destroy(id)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        entrieCustomForm(__, payload) {
            return FormsServicesInstance.entries(payload)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
        },
        updateAssistence(__, payload) {
            const id = payload.id
            return FormsServicesInstance.updateAssistence(id, payload)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
        },
        deleteEntrie(__, { id = '' }) {
            return FormsServicesInstance.destroyEntrie(id)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
        },
        export(__, { payload }) {
            const { custom_form_id } = payload
            return FormsServicesInstance.export(custom_form_id, payload)
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
        },
    },
}